import { Veriff } from "@veriff/js-sdk";
import { VERIFF_HOST_URL } from "@/core/variables";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import MODULE_CUSTOM_CONFIG from "@/graphql/queries/modules/CustomConfig.gql";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isKycDialogOpen: false,
      isVeriffSessionStarted: false,
      veriff: null,
      moduleKycCustomConfig: null
    }
  },
  watch: {
    moduleKycCustomConfig(newValue) {
      if (!newValue) return;
      this.veriffInit(this.me);
    }
  },
  async me(newValue) {
    try {
      if (newValue) {
        this.veriffInit(newValue);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    veriffInit(me) {
      /**
       * checking if kyc module custom_config doesn't include
       * api_key, don't continue, because without it we can't init
       * veriff instance
       * this.veriff - if veriff instance was already created, don't render
       * this part of code
       * **/
      if (
        !me ||
        this.veriff ||
        !this.moduleKycCustomConfig ||
        !this?.moduleKycCustomConfig?.api_key
      )
        return;

      const self = this;

      const kycModule = this.config.modules.find((module) => {
        return module.module === "kyc";
      });

      if (!kycModule || me.kyc) return;

      this.isKycAlertShow = true;

      this.veriff = Veriff({
        host: VERIFF_HOST_URL,
        apiKey: this.moduleKycCustomConfig.api_key,
        parentId: "veriff-root",
        onSession: function (err, response) {
          self.isVeriffSessionStarted = true;
          createVeriffFrame({
            url: response.verification.url,
            lang: self.$i18n.locale,
            onEvent: function (msg) {
              switch (msg) {
                case MESSAGES.STARTED:
                  self.isVeriffSessionStarted = true;
                  break;
                case MESSAGES.CANCELED:
                  self.isVeriffSessionStarted = false;
                  break;
                case MESSAGES.SUBMITTED:
                  window.location.reload();
                  break;
                case MESSAGES.FINISHED:
                  window.location.reload();
                  break;
              }
            },
          });
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      me: "user/getUser",
      userPartnerProgram: "user/getUserPartnerProgram",
      moduleDefiIndexStats: "user/getModuleDefiIndexStats",
      moduleDefiIndexes: "user/getModuleDefiIndexes",
      getShowRefLink: "app/showRefLink",
      isProducts: "modules/products/isProducts",
      config: "app/getConfig",
      isUniLevelPlan: "app/isUniLevelPlan",
      enabledModules: "modules/getEnabledModules",
      getSubscription: "user/getSubscription",
      whiteLabelId: "app/getWhiteLabelUuid",
    }),
  },
  apollo: {
    moduleCustomConfig: {
      query: MODULE_CUSTOM_CONFIG,
      variables() {
        return {
          whitelabel_uuid: this.whiteLabelId,
          module: "kyc",
        };
      },
      result({ data }) {
        let payload = data?.moduleCustomConfig?.custom_config;
        if (!payload) return;
        this.moduleKycCustomConfig = JSON.parse(payload);
      },
    }
  }
}
