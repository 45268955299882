<template>
  <v-dialog
    v-model="showDialog"
    no-click-animation
    persistent
    width="480"
    overlay-color="rgba(39, 50, 64)"
    overlay-opacity="0.6"
    :transition="false"
  >
    <div class="identity-verification-dialog">
      <IconCustom
        class="close-icon"
        name="icn24_close"
        color="#CCD1E3"
        @click.native="showDialog = false"
      />
      <div class="identity-verification-dialog__title">
        {{ $t("defiDashboard.identityVerification") }}
      </div>
      <small class="identity-verification-dialog__description">
        {{ $t("defiDashboard.identityVerificationDescription") }}
      </small>
      <div class="identity-verification-dialog__data">
        <v-text-field
          v-model.trim="form.firstName"
          :error-messages="firstNameErrors"
          outlined
          hide-details="auto"
          :label="$t('defiDashboard.firstName')"
          required
          @blur="$v.form.firstName.$touch()"
        />
        <v-text-field
          v-model.trim="form.lastName"
          :error-messages="lastNameErrors"
          hide-details="auto"
          outlined
          :label="$t('defiDashboard.lastName')"
          required
          @blur="$v.form.lastName.$touch()"
        />
        <div
          id="veriff-root"
          style="display: none; width: 0; height: 0">
        </div>
        <u-btn
          :loading="loading"
          :disabled="$v.$invalid"
          class="identity-verification-dialog__submit"
          color="primary"
          @click="handleFormSubmit"
        >
          {{ $t("defiDashboard.startVerification") }}
        </u-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";
import UBtn from "@/components/core/ui/UBtn.vue";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";

let initialFormState = {
  firstName: "",
  lastName: ""
};

export default {
  components: { UBtn, IconCustom },
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean
    },
    veriff: {
      type: Object
    },
    isVeriffSessionStarted: {
      type: Boolean
    }
  },
  validations() {
    return {
      form: this.rules
    };
  },
  data() {
    return {
      loading: false,
      form: initialFormState
    };
  },
  computed: {
    ...mapGetters({
      me: "user/getUser"
    }),
    rules() {
      return {
        firstName: {
          required
        },
        lastName: {
          required
        }
      };
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;
      !this.$v.form.firstName.required &&
      errors.push(this.$t("rules.reqField"));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;
      !this.$v.form.lastName.required && errors.push(this.$t("rules.reqField"));
      return errors;
    }
  },
  watch: {
    isVeriffSessionStarted(newValue) {
      if (!newValue) return;
      this.showDialog = false;
      this.loading = false;
      this.form = {
        firstName: "",
        lastName: ""
      };
    }
  },
  methods: {
    handleFormSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      this.veriff.setParams({
        person: {
          givenName: this.form.firstName,
          lastName: this.form.lastName
        },
        vendorData: this.me.uuid
      });

      this.veriff.mount({
        formLabel: {
          vendorData: this.me.uuid
        }
      });

      let btn = document.querySelector("#veriff-submit-btn");
      btn.click();
    }
  }
};
</script>

<style scoped lang="scss">
.identity-verification-dialog {
  position: relative;
  text-align: center;
  padding: 40px;
  border-radius: 20px;
  background: var(--surface-background, #fff);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);

  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
  }

  &__title {
    text-align: center;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  &__submit {
    width: 100%;
  }

  &__description {
    font-size: 12px;
    text-align: center;
  }
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
