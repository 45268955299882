var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "no-click-animation": "",
      "persistent": "",
      "width": "480",
      "overlay-color": "rgba(39, 50, 64)",
      "overlay-opacity": "0.6",
      "transition": false
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('div', {
    staticClass: "identity-verification-dialog"
  }, [_c('IconCustom', {
    staticClass: "close-icon",
    attrs: {
      "name": "icn24_close",
      "color": "#CCD1E3"
    },
    nativeOn: {
      "click": function ($event) {
        _vm.showDialog = false;
      }
    }
  }), _c('div', {
    staticClass: "identity-verification-dialog__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("defiDashboard.identityVerification")) + " ")]), _c('small', {
    staticClass: "identity-verification-dialog__description"
  }, [_vm._v(" " + _vm._s(_vm.$t("defiDashboard.identityVerificationDescription")) + " ")]), _c('div', {
    staticClass: "identity-verification-dialog__data"
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.firstNameErrors,
      "outlined": "",
      "hide-details": "auto",
      "label": _vm.$t('defiDashboard.firstName'),
      "required": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.firstName.$touch();
      }
    },
    model: {
      value: _vm.form.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firstName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "error-messages": _vm.lastNameErrors,
      "hide-details": "auto",
      "outlined": "",
      "label": _vm.$t('defiDashboard.lastName'),
      "required": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.lastName.$touch();
      }
    },
    model: {
      value: _vm.form.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.lastName"
    }
  }), _c('div', {
    staticStyle: {
      "display": "none",
      "width": "0",
      "height": "0"
    },
    attrs: {
      "id": "veriff-root"
    }
  }), _c('u-btn', {
    staticClass: "identity-verification-dialog__submit",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.$v.$invalid,
      "color": "primary"
    },
    on: {
      "click": _vm.handleFormSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("defiDashboard.startVerification")) + " ")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }